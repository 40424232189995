/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./download.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./download.component";
var styles_DownloadComponent = [i0.styles];
var RenderType_DownloadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DownloadComponent, data: {} });
export { RenderType_DownloadComponent as RenderType_DownloadComponent };
export function View_DownloadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "section", [["id", "download"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "row d-md-flex text-center wow fadeIn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "col-md-6 offset-md-3 col-sm-10 offset-sm-1 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download & get started today!"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["href", "https://itunes.apple.com/us/app/cooking-calculator-pro/id1396408499?ls=1&mt=8"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["class", "store-img"], ["src", "assets/img/apple_store.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [["class", "mt-4 lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We also appreciate any feedback, features that you would like to in the Cooking Calculator Pro please get in touch with us."])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
export function View_DownloadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "component-download", [], null, null, null, View_DownloadComponent_0, RenderType_DownloadComponent)), i1.ɵdid(1, 49152, null, 0, i2.DownloadComponent, [], null, null)], null, null); }
var DownloadComponentNgFactory = i1.ɵccf("component-download", i2.DownloadComponent, View_DownloadComponent_Host_0, {}, {}, []);
export { DownloadComponentNgFactory as DownloadComponentNgFactory };
