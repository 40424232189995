<section class="bg-white" id="features">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-8 mx-auto text-center wow fadeIn">
                <h2 class="text-primary">Why Choose Cooking Calculator Pro?</h2>
                <p class="lead">
                    Besides it's conversion calculator and recipe multiplier.
                </p>
            </div>
        </div>
        <div class="row d-md-flex mt-4 text-center">
            <div class="col-sm-6 col-md-3 mt-2">
                <div class="card border-none wow fadeIn">
                    <div class="card-body">
                        <div class="icon-box">
                            <i class="material-icons dp36">phonelink</i>
                        </div>
                        <h5 class="card-title text-primary pt-5">Easy Math</h5>                        
                        <p class="card-text">                            
                            You can  and view the results rounded to standard cooking fractions.
                        </p>                        
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 mt-2">
                <div class="card border-none wow fadeIn">
                    <div class="card-body">
                        <div class="icon-box">
                            <i class="material-icons dp36">invert_colors</i>
                        </div>
                        <h5 class="card-title text-primary pt-5">Volume/Liquid</h5>
                        <p class="card-text">
                            Use this section to convert liquid measurments.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 mt-2">
                <div class="card border-none wow fadeIn">
                    <div class="card-body">
                        <div class="icon-box">
                            <i class="material-icons dp36">crop</i>
                        </div>
                        <h5 class="card-title text-primary pt-5">Weight/Dry</h5>
                        <p class="card-text">
                            Convert any dry measurment to an weight unit.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 mt-2">
                <div class="card border-none wow fadeIn">
                    <div class="card-body">
                        <div class="icon-box">
                            <i class="material-icons dp36">cloud_queue</i>
                        </div>
                        <h5 class="card-title text-primary pt-5">Temperature</h5>
                        <p class="card-text">
                            Did we meantion that you also have an oven temperature conversion?<br/>
                        </p>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</section>
