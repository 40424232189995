<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-primary sticky-navigation">
    <div class="container">
        <a class="navbar-brand" href="/">
            <img src="assets/img/logo.png" class="logo" title="Cooking Calculator Pro" width="50" />
        </a>
        
        <button class="navbar-toggler navbar-toggler-right" 
            type="button" data-toggle="collapse" 
            data-target="#navbarCollapse" 
            aria-controls="navbarCollapse" 
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item mr-3">
                    <a class="nav-link page-scroll" href="#features">Features</a>
                </li>                
                <li class="nav-item mr-3">
                    <a class="nav-link page-scroll" href="#support">Support</a>
                </li>
                <li class="nav-item mr-3">
                    <a class="nav-link" href="/privacy">Privacy</a>
                </li>
                <li class="nav-item mr-3">
                    <a class="nav-link page-scroll" href="#support">Contact</a>
                </li>
                <li class="nav-item mr-3">
                    <a href="https://itunes.apple.com/us/app/cooking-calculator-pro/id1396408499?ls=1&mt=8" target="_blank" class="nav-link page-scroll">
                    Download</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
