<section class="bg-img">
    <div class="container">
        <div class="row">
            <div class="col-md-6 text-white wow fadeIn topsection">
                <h1 class="text-primary">Cooking Calculator Pro</h1>
                <h3 class="text-primary" style="margin-top: 24px;">
                    <span class="text-orange">Your cooking little helper!</span>
                </h3>
                <p class="mt-4 lead text-primary">
                    It will help you easily convert the most common measuring units used in cooking and baking!                    
                </p>
                <p class="mt-5">
                    <a href="https://itunes.apple.com/us/app/cooking-calculator-pro/id1396408499?ls=1&mt=8" target="_blank">
                        <img src="assets/img/apple_store.png" class="store-img"/> 
                    </a>
                </p>
            </div>
            <div class="col-md-6 d-none d-md-block wow fadeInRight">
                <img class="img-fluid mx-auto d-block appstore-img" src="assets/img/1.png" width="300" />
            </div>
        </div>
    </div>
</section>