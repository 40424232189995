/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./support.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/main-section/main-section.component.ngfactory";
import * as i3 from "../../components/main-section/main-section.component";
import * as i4 from "../../components/main-features/main-features.component.ngfactory";
import * as i5 from "../../components/main-features/main-features.component";
import * as i6 from "../../components/extra-features/extra-features.component.ngfactory";
import * as i7 from "../../components/extra-features/extra-features.component";
import * as i8 from "../../components/download/download.component.ngfactory";
import * as i9 from "../../components/download/download.component";
import * as i10 from "../../components/contact/contact.component.ngfactory";
import * as i11 from "../../components/contact/contact.component";
import * as i12 from "./support.component";
var styles_SupportComponent = [i0.styles];
var RenderType_SupportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SupportComponent, data: {} });
export { RenderType_SupportComponent as RenderType_SupportComponent };
export function View_SupportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "main-section", [], null, null, null, i2.View_MainSectionComponent_0, i2.RenderType_MainSectionComponent)), i1.ɵdid(1, 49152, null, 0, i3.MainSectionComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "main-features", [], null, null, null, i4.View_MainFeaturesComponent_0, i4.RenderType_MainFeaturesComponent)), i1.ɵdid(3, 49152, null, 0, i5.MainFeaturesComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "extra-features", [], null, null, null, i6.View_ExtraFeaturesComponent_0, i6.RenderType_ExtraFeaturesComponent)), i1.ɵdid(5, 49152, null, 0, i7.ExtraFeaturesComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "component-download", [], null, null, null, i8.View_DownloadComponent_0, i8.RenderType_DownloadComponent)), i1.ɵdid(7, 49152, null, 0, i9.DownloadComponent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "component-contact", [], null, null, null, i10.View_ContactComponent_0, i10.RenderType_ContactComponent)), i1.ɵdid(9, 49152, null, 0, i11.ContactComponent, [], null, null)], null, null); }
export function View_SupportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-support", [], null, null, null, View_SupportComponent_0, RenderType_SupportComponent)), i1.ɵdid(1, 49152, null, 0, i12.SupportComponent, [], null, null)], null, null); }
var SupportComponentNgFactory = i1.ɵccf("app-support", i12.SupportComponent, View_SupportComponent_Host_0, {}, {}, []);
export { SupportComponentNgFactory as SupportComponentNgFactory };
